<template>
    <main>
        <div class="row">
            <div class="col-lg-6">
                <div class="card card-scrollable card-waves">
                    <div class="card-header"><i class="fas fa-history fa-fw"></i> Historial Vacunas</div>
                    <div class="card-body">
                        <div v-if="Object.keys(pagination.data).length">
                            <div class="timeline timeline-xs">
                                <div class="timeline-item" v-for="(item, i) in pagination.data" :key="`d_${i}`">
                                    <div class="timeline-item-marker">
                                        <div class="timeline-item-marker-text "> {{item.fecha}}</div>
                                        <div class="timeline-item-marker-indicator bg-info"></div>
                                    </div>
                                    <div class="timeline-item-content">
                                        <h6>{{item.vacuna.nombre}}</h6>
                                        <li class="text-body">Dosis: {{item.dosis.nombre_dosis}}</li>
                                        <li class="text-body">Sede: {{item.sede.NOMBRE_SEDE}}</li>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="alert alert-warning" v-else>Sin historial...</div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="card card-scrollable">
                    <div class="card-header"><i class="fas fa-percent fa-fw"></i> Porcentaje Vacunas</div>
                    <div class="card-body">
                        <div v-for="item in porcentaje_vacunas" :key="item.id">
                            <h4 class="small">
                                <span class="text-black">{{item.nombre}}</span> ,
                                <span class="text-body">Dosis aplicadas: {{item.dosis_aplicadas}} ,</span>
                                <span class="float-end fw-bold text-black-75"> Porcentaje: {{item.porcentaje}}%</span>
                            </h4>
                            <div class="progress mb-4">
                                <div class="progress-bar bg-info"
                                role="progressbar"
                                :style="`width:${item.porcentaje}%`"
                                :aria-valuenow="item.porcentaje" 
                                aria-valuemin="0" 
                                aria-valuemax="100">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-2">
            <!-- <div class="col-xl-12">
                <div class="card-body">
                    <data-grid :data="pagination"
                        @changePage="changePage($event)"
                        @perPageChange="perPageChange($event)"
                        @search="search($event)">
                        <template #grid>
                            <grid-table>
                                <template #head>
                                    <grid-cell-header class="bg-light"  v-for="(h,i) in headers" :key="`h_${i}`">{{h}}</grid-cell-header>
                                </template>
                                <template #body>
                                    <grid-row v-for="(item, i) in pagination.data" :key="`d_${i}`">
                                        <grid-cell class="text-center small">
                                        {{i+1}}
                                        </grid-cell>
                                        <grid-cell class="text-center small">
                                            {{item.fecha}}
                                        </grid-cell>
                                        <grid-cell class="text-center ">
                                            {{item.vacuna.nombre}}
                                        </grid-cell>
                                        <grid-cell class="text-center ">
                                            {{item.dosis.nombre_dosis}}
                                        </grid-cell>
                                         <grid-cell class="text-center ">
                                            {{item.sede.NOMBRE_SEDE}}
                                        </grid-cell>
                                        <grid-cell class="text-center ">
                                            {{item.vacunador.name}}
                                        </grid-cell>
                                        <grid-cell class="text-center   ">
                                            <div class="form-group ">
                                            
                                            </div>
                                        </grid-cell>
                                    </grid-row>
                                </template>
                            </grid-table>
                        </template>
                    </data-grid>
                </div>
            </div> -->
            <!-- <div class="col-lg-6">
                <div class="card card-header-actions">
                    <div class="card-header ">
                        Registro vacunas
                        <button type="button" class="btn btn-success lift lift-sm" @click="guardar();">
                            <i class="fa fa-save fa-fw"></i>
                        </button>   
                    </div>
                    <div class="card-body">
                        <div class="form-group">
                            <label class="form-label">Seleccione una vacuna</label>
                            <select name="" id="" class="form-control form-control-sm" v-model="pai.vacuna">
                                <option :value="item" v-for="item in vacunas" :key="item.id">{{item.nombre}}</option>
                            </select>
                            <div class="form-check form-check-inline mt-2 alert alert-info mb-0"
                                v-for="opc in dosis" :key="opc.id">
                                <input 
                                    class="form-check-input"
                                    type="radio"
                                    :name="`enum_${opc.id}`"
                                    :id="opc.id"
                                    :value="opc.id"
                                    v-model="pai.dosis"
                                />
                                <label class="form-check-label">{{opc.nombre_dosis}}</label>
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="form-label">Sede</label>
                            <VSelectSede v-model="pai.sede" :mode="'object'"  :class="{'is-error' : $v.pai.sede.$error}" ></VSelectSede>
                            <span v-if="!$v.pai.sede.required && $v.pai.sede.$dirty" class="text-danger">
                                <i class="fas fa-exclamation-triangle"></i> Este campo es requerido.
                            </span>
                        </div>
                        <div class="form-group">
                            <label class="form-label">Fecha</label>
                            <input type="date" class="form-control form-control-sm" v-model="pai.fecha" 
                            :class="{'is-invalid':$v.pai.fecha.$error && $v.pai.fecha.$dirty}">
                        </div>
                    </div>
                </div>
            </div> -->
            <div class="col-lg-12">
                <div class="card card-waves mb-4 mt-2 card-header-actions">
                    <div class="card-header ">
                        Registro vacunas
                        <button type="button" class="btn btn-success lift lift-sm" @click="guardar();">
                            <i class="fa fa-save fa-fw"></i>
                        </button>   
                    </div>
                    <div class="card-body ">
                        <div class="row  justify-content-between">
                            <div class="col">
                                <label class="toggle">
                                    <input class="toggle-checkbox" type="checkbox" v-model="case_extreme">
                                    <div class="toggle-switch"></div>
                                    <span class="toggle-label">Caso extremo</span>
                                  </label>
                                <!-- <div class="form-check">
                                    <input type="checkbox" class="form-check-input" v-model="case_extreme" >
                                    <label class="form-check-label">Caso extremo</label>
                                </div> -->
                                <div  v-if="case_extreme">
                                    <label class="form-label text-danger">Debe ingresar una observación en este caso extremo</label>
                                    <textarea class="form-control border-danger" cols="30" rows="5" v-model="pai.observaciones_case_ex"></textarea>
                                </div>
                               
                                <div class="form-group mt-2">
                                    <label class="form-label">Seleccione una vacuna</label>
                                    <select name="" id="" class="form-control form-control-sm" v-model="pai.vacuna">
                                        <option :value="item" v-for="item in vacunas" :key="item.id">{{item.nombre}}</option>
                                    </select>
                                    <div class="form-check form-check-inline mt-2 alert alert-info mb-0"
                                        v-for="opc in dosis" :key="opc.id">
                                        <input 
                                            class="form-check-input"
                                            type="radio"
                                            :name="`enum_${opc.id}`"
                                            :id="opc.id"
                                            :value="opc.id"
                                            v-model="pai.dosis"
                                        />
                                        <label class="form-check-label">{{opc.nombre_dosis}}</label>
                                    </div>
                                    <div class="form-group">
                                        <label class="form-label">Sede</label>
                                        <VSelectSede v-model="pai.sede" :mode="'object'"  :class="{'is-error' : $v.pai.sede.$error}" ></VSelectSede>
                                        <span v-if="!$v.pai.sede.required && $v.pai.sede.$dirty" class="text-danger">
                                            <i class="fas fa-exclamation-triangle"></i> Este campo es requerido.
                                        </span>
                                    </div>
                                    <div class="form-group">
                                        <label class="form-label">Fecha</label>
                                        <input type="date" class="form-control form-control-sm" v-model="pai.fecha" 
                                        :class="{'is-invalid':$v.pai.fecha.$error && $v.pai.fecha.$dirty}">
                                    </div>
                                    
                                    <div class="form-group">
                                        <label class="form-label">Lote de vacuna</label>
                                        <input type="text" class="form-control form-control-sm" v-model="pai.lote_vacuna" 
                                        >
                                    </div>
                                    <div class="form-group">
                                        <label class="form-label">Lote de jeringa</label>
                                        <input type="text" class="form-control form-control-sm" v-model="pai.lote_jeringa" 
                                       >
                                    </div>
                                    <div class="form-group" v-if="pai.vacuna.contraindicaciones!=0">   
                                        <label for="" class="form-label">Contraindicaciones</label>
                                        <select class="form-control form-control-sm" v-model="pai.id_contraindicacion">
                                            <option v-for="item in pai.vacuna.contraindicaciones" :key="item.id" :value="item.id" >{{item.contraindicacion}}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="col d-none d-lg-block  mt-8 ">
                                <img class="img-fluid px-xl-4 mt-xxl-n0" src="@/assets/img-generales/vacunas.png" width="400px">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>
<script>
import VSelectSede from '../../components/common/vSelectSede';

//import DataGrid from "../../components/common/utilities/DataGrid/DataGrid";
//import GridTable from "../../components/common/utilities/DataGrid/GridTable";
//import GridCellHeader from "../../components/common/utilities/DataGrid/GridCellHeader";
//import GridRow from "../../components/common/utilities/DataGrid/GridRow";
//import GridCell from "../../components/common/utilities/DataGrid/GridCell";
//import vacunaService from '../../services/vacunaService';
import embarazoService from '../../services/embarazoService';
import {isEmpty} from "lodash";
import {required} from "vuelidate/lib/validators";
import Toast from '../../components/common/utilities/toast';

import registroPaiService from "../../services/regitroPaiService";
export default {
    props: ['usuario','idUsuario'],
    components:{
        VSelectSede
    },
    data() {
        return {
            headers : [
               '#',
               'Fecha',
               'Vacuna',
               'Dosis',
               'Sede',
               'Vacunador',
               ''
           ],
            pagination : {
               data : [],
               current_page : 1,
               page : 1,
               per_page : 5,
               search : '',
               total: '',
            },
            vacunas: [],
            pai:{
                id_usuario:this.idUsuario,
                vacuna:{},
                dosis : '',
                fecha:'',
                sede: {},
                lote_vacuna:'',
                lote_jeringa:'',
                id_contraindicacion:'',
                observaciones_case_ex:''
            },
            embarazo_actual: {},
            porcentaje_vacunas:[],
            case_extreme:false
        }
    },
   validations (){
        return {
            pai:{
                fecha:{required},
                sede: {required},
            },
        }
    },
    methods : {
        async porcentajeVacunas(){
            const response = await registroPaiService.countVacunas({
                id_usuario : this.usuario.id,
                sexo : this.usuario.sexo,
                fecha_nacimiento : this.usuario.fecha_nacimiento,
                ...(!isEmpty(this.embarazo_actual) && this.embarazo_actual.activo === 'S' ? {gestante : 'S'} : {})
            });
            this.porcentaje_vacunas = response.data.map(x => {
                return {
                    id : x.id,
                    nombre : x.nombre,
                    dosis_aplicadas : x.dosis_aplicadas,
                    numero_dosis : x.dosis_count,
                    porcentaje:Math.round((x.dosis_aplicadas*100)/x.dosis_count)
                }
            });
        },
        async cargarHistorial(){
            const response = (await registroPaiService.showByIdUser(this.pagination.search, this.pagination,this.idUsuario))?.data;
                this.pagination.data = response.data;
                this.pagination.current_page  = response.current_page;
                this.pagination.total = response.total;
        },
        changePage(page) {
            this.pagination.page = page;
            this.cargarHistorial();
        },
        perPageChange(per_page) {
            this.pagination.per_page = per_page;
            this.pagination.page = 1;
            this.cargarHistorial();
        },
        search(query){
            this.pagination.search = query;
            this.pagination.page = 1;
            this.cargarHistorial();
        }, 
        async cargarVacunas(){
           
            const response = await registroPaiService.index({
                id_usuario : this.usuario.id,
                sexo : this.usuario.sexo,
                case_extreme:this.case_extreme?1:0,
                fecha_nacimiento : this.usuario.fecha_nacimiento,
                ...(!isEmpty(this.embarazo_actual) && this.embarazo_actual.activo === 'S' ? {gestante : 'S'} : {}),
               
            });
            this.vacunas = response.data.map(x => {
                return {
                id : x.id,
                nombre : x.nombre,
                dosis : x.dosis.map(y => {
                    return {
                    ...y,
                    }
                }),
                contraindicaciones : x.contraindicaciones.map(z => {
                    return {
                    ...z,
                    }
                })
                }
            });
        },
        async guardar(){
            try {
                this.$v.$touch();

                if (isEmpty(this.pai.vacuna)) {
                    Toast.fire({
                        icon: 'error',
                        title: 'Debe seleccionar una vacuna'
                    });
                    return;
                }

                if (this.pai.dosis==='') {
                    Toast.fire({
                        icon: 'error',
                        title: 'Debe seleccionar una dosis'
                    });
                    return;
                }

                if (this.case_extreme && this.pai.observaciones_case_ex==='') {
                    Toast.fire({
                        icon: 'error',
                        title: 'Debe ingresar una observación del caso extremo'
                    });
                    return;
                }

                if(this.$v.$invalid) return;

                this.LoaderSpinnerShow();

                await registroPaiService.store(this.pai);
                
                this.LoaderSpinnerHide();

                this.limpiarCampos();
                this.$v.$reset();

                await Toast.fire({
                    icon: 'success',
                    title: 'Datos guardados con exito'
                });

                this.cargarHistorial();
                this.cargarVacunas();
                this.porcentajeVacunas();
                
            } catch (error) {
                console.log(error);
                this.LoaderSpinnerHide();
                Toast.fire({
                    icon: 'error',
                    title: 'Ocurrio un error al procesar la solicitud'
                });
            }
        },
        limpiarCampos(){
            this.pai.fecha='';
            this.pai.vacuna={};
            this.pai.sede={};
            this.pai.dosis='';
            this.pai.lote_vacuna='';
            this.pai.lote_jeringa='';
            this.pai.observaciones_case_ex='';
            this.case_extreme=false;
        },
    },
    watch:{
        case_extreme:function(){
            this.cargarVacunas();
        }
    },
    async created() {
        //this.LoaderSpinnerShow();
        this.cargarHistorial();
        const res_embarazo_actual= await embarazoService.showByLast(this.idUsuario);
        this.embarazo_actual=res_embarazo_actual.data;
        this.cargarVacunas();
        this.porcentajeVacunas();
        //this.LoaderSpinnerHide();
    },
    computed : {
        dosis(){
            if(!isEmpty(this.pai.vacuna)){
                return this.pai.vacuna.dosis;
            }
            return [];
        }
    }
}
</script>
<style scoped>
    .timeline-item-marker-text{
        width: auto !important; 
    }
    /*toogle checkbox*/
    *,
*:before,
*:after {
  box-sizing: border-box;
}

body {
  font-family: -apple-system, ".SFNSText-Regular", "Helvetica Neue", "Roboto", "Segoe UI", sans-serif;
}

.toggle {
  cursor: pointer;
  display: inline-block;
}

.toggle-switch {
  display: inline-block;
  background: #ccc;
  border-radius: 16px;
  width: 52px;
  height: 26px;
  position: relative;
  vertical-align: middle;
  transition: background 0.25s;
}
.toggle-switch:before, .toggle-switch:after {
  content: "";
}
.toggle-switch:before {
  display: block;
  background: linear-gradient(to bottom, #fff 0%, #eee 100%);
  border-radius: 50%;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.25);
  width: 18px;
  height: 18px;
  position: absolute;
  top: 4px;
  left: 4px;
  transition: left 0.25s;
}
.toggle:hover .toggle-switch:before {
  background: linear-gradient(to bottom, #fff 0%, #fff 100%);
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.5);
}
.toggle-checkbox:checked + .toggle-switch {
  background: #0872fd;
}
.toggle-checkbox:checked + .toggle-switch:before {
  left: 30px;
}

.toggle-checkbox {
  position: absolute;
  visibility: hidden;
}

.toggle-label {
  margin-left: 5px;
  position: relative;
  top: 2px;
  font-size: 18px;
}
</style>