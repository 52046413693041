import Service from "@/services/Service";

const baseurl = "api/acudiente";

export default {
    index(params={}) {
        console.log(params);
        return Service.get(`${baseurl}`,{
            params: params
        });
    },
    store(data) {
        return Service.post(`${baseurl}/store`, data);
    },
    show(id) {
        return Service.get(`${baseurl}/show/${id}`);
    },
    update(data) {
        return Service.put(`${baseurl}/update`, data);
    }
}
